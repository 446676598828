<template>
  <b-card>
    <b-overlay :show="isProcessing">
      <validation-observer ref="companySettingsForm">
        <h3 class="mb-2 text-center">
          Transfer Fees
        </h3>
        <hr class="mb-3">
        <div class="d-flex justify-content-between">
          <validation-provider
            #default="{ errors }"
            name="SWIFT transfer fee"
            rules="required|min_value:0"
          >
            <b-form-group label="SWIFT Transfer Fee">
              <b-input-group :prepend="currency">
                <b-form-input
                  v-model="form.swiftCharge"
                  type="number"
                  :min="0"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="ACH transfer fee"
            rules="required|min_value:0"
          >
            <b-form-group label="ACH Transfer Fee">
              <b-input-group :prepend="currency">
                <b-form-input
                  v-model="form.achCharge"
                  type="number"
                  :min="0"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="FX transfer fee"
            rules="required|min_value:0"
          >
            <b-form-group label="FX Transfer Fee">
              <b-input-group :prepend="currency">
                <b-form-input
                  v-model="form.fxCharge"
                  type="number"
                  :min="0"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </validation-observer>

      <b-button
        class="float-right mt-2"
        variant="primary"
        :disabled="isProcessing"
        @click="handleSubmit"
      >
        Update
      </b-button>
    </b-overlay>
  </b-card>
</template>

<script>
import useApollo from '@/plugins/graphql/useApollo'
import {
  BFormGroup, BInputGroup, BFormInput, BOverlay, BCard, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min_value } from 'vee-validate/dist/rules'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BOverlay,
    BCard,
    BButton,
    ValidationObserver,
    ValidationProvider,
  },
  data() {
    return {
      required,
      min_value,
      form: {
        swiftCharge: 0,
        achCharge: 0,
        fxCharge: 0,
      },
      isProcessing: false,
      currency: this.$store.state.project.company.currency,
    }
  },
  mounted() {
    this.getCharges()
  },
  methods: {
    getCharges() {
      this.isProcessing = true
      useApollo.company.getBillingCharges().then(response => {
        const charges = response.data.workzone.extraInformations?.data[0]?.information
        this.form.id = charges?.id
        this.form.swiftCharge = charges?.swiftCharge ?? 0
        this.form.achCharge = charges?.achCharge ?? 0
        this.form.fxCharge = charges?.fxCharge ?? 0
      }).finally(() => { this.isProcessing = false })
    },
    handleSubmit() {
      this.isProcessing = true
      const input = {
        swiftCharge: Number(this.form.swiftCharge),
        achCharge: Number(this.form.achCharge),
        fxCharge: Number(this.form.fxCharge),
      }
      if (this.form.id) input.id = this.form.id
      this.$refs.companySettingsForm.validate().then(success => {
        if (success) {
          useApollo.company.updateBillingCharges({ input }).then(response => {
            this.showSuccessMessage({
              data: {
                message: response.data.updateCompanyBillingCharges.message,
              },
            })
          }).finally(() => {
            this.getCharges()
          })
        }
      })
    },
  },
}
</script>
