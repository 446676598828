<template>
  <div>
    <b-tabs
      pills
      card
      vertical
      nav-class="nav-left"
      nav-wrapper-class="w-25"
    >
      <b-tab active>
        <template #title>
          <span
            class="font-weight-bold"
          >
            <feather-icon
              icon="DollarSignIcon"
              size="16"
            />
            Transfer Fees
          </span>
        </template>
        <transfer-charges />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import TransferCharges from './components/TransferCharges.vue'

export default {
  components: {
    BTabs,
    BTab,
    TransferCharges,
  },
}
</script>
